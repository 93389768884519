<template>
  <div :class="['stage',{'stage--ar' : type === 'AR'},
												{'stage--dl' : type === 'DL'},
												{'stage--ds' : type === 'DS'},
												{'stage--ed' : type === 'ED'},
												{'stage--hv' : type === 'HV'},
												{'stage--sd' : type === 'SD'},
												{'stage--wm' : type === 'WM'}]" theme="theme-ds">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		type: String,
	},
}
</script>

<style lang="scss" scoped>
.stage{
	width: 100%;
}
.stage--ar{
	display: var(--stage-ar-display);
}
.stage--dl{
	display: var(--stage-dl-display);
}
.stage--ds{
	display: var(--stage-ds-display);
}
.stage--ed{
	display: var(--stage-ed-display);
}
.stage--hv{
	display: var(--stage-hv-display);
}
.stage--sd{
	display: var(--stage-sd-display);
}
.stage--wm{
	display: var(--stage-wm-display);
}
</style>

<!-- Used in : DS -->
